import PrivatePolicy from "./utils/PrivatePolicy";

class Site {
	public static PrivatePolicy = PrivatePolicy;

	public static discover($parent?: JQuery): void {
		(<any> $('.lightbox, .fancybox, a[rel="lightbox"]', $parent)).fancybox({
			titlePosition: 'over'
		});

		let $mainmenu = $('#mainmenu');

		$('.item', $mainmenu).each((ix, el) => {
			const $item = $(el);
			const $ul = $('> .submenu', $item);
			const children = $item.hasClass('children');

			const openFunc = () => {
				$ul
					.stop(true, true)
					.animate({ opacity: 'show', height: 'show' }, 150);
			}

			if (children) {
				$item.on('click', (e) => {
					if (!$ul.is(':visible')) {
						e.preventDefault();
						openFunc();
					}
				});

				$item.hover(
					() => {
						const wx = $(window).innerWidth();
						if (wx > 750) {
							openFunc();
						}
					},

					function() {
						$ul.stop(true,true).delay(400)
							.animate({ opacity: 'hide', height: 'hide' }, 100);
					}
				);
			}
		});

		$('.menubutton', $mainmenu).click(function(e) {
			if ($mainmenu.hasClass('opened')) {
				$mainmenu.removeClass('opened');
			} else {
				$mainmenu.addClass('opened');
			}
		});
	}

    public static initWcag(): void {
        const $html = $('html');

        const $zooms = $('.wcagcontrols__zoom button');
        const $contrats = $('.wcagcontrols__contrast button');

        let wcagzoom = parseInt(window.localStorage.getItem('wcag-zoom'));
        let wcagcontrast = parseInt(window.localStorage.getItem('wcag-contrast'));

        wcagzoom = isNaN(wcagzoom) ? null : wcagzoom;
        wcagcontrast = isNaN(wcagcontrast) ? null : wcagcontrast;

        const setZoom = (ix: number) => {
            $zooms.removeClass('wcagcontrols__zoom--selected');
            $html.removeClass('wcag-zoom wcag-zoom--1 wcag-zoom--2 wcag-zoom--3');

            if (ix != null) {
                $zooms.eq(ix).addClass('wcagcontrols__zoom--selected');
                $html.addClass('wcag-zoom wcag-zoom--' + (ix + 1));
            }

            wcagzoom = ix;
        };

        const setContrast = (ix: number) => {
            $contrats.removeClass('wcagcontrols__contrast--selected');
            $html.removeClass('wcag-contrast wcag-contrast--1 wcag-contrast--2 wcag-contrast--3');

            if (ix != null) {
                $contrats.eq(ix).addClass('wcagcontrols__contrast--selected');
                $html.addClass('wcag-contrast wcag-contrast--' + (ix + 1));
            }

            wcagcontrast = ix;
        };

        $contrats.on('click', function (e) {
            e.preventDefault();

            let ix = $(this).index();

            if (ix == wcagcontrast) {
                ix = null;
            }

            setContrast(ix);

            if (ix != null) {
                window.localStorage.setItem('wcag-contrast', ix.toString());
            } else {
                window.localStorage.removeItem('wcag-contrast');
            }
        });

        $zooms.on('click', function (e) {
            e.preventDefault();

            const ix = $(this).index();

            setZoom(ix);

            window.localStorage.setItem('wcag-zoom', ix.toString());
        });

        setZoom(wcagzoom);
        setContrast(wcagcontrast);
	}

	public static initHtml(): void {
		$('html').attr('id', 'html');

		this.initWcag();
	}

	public static init(): void {
		(<any> window).Site = Site;

		$(() => {
			this.initHtml();
			this.discover();
		});

		$(window).on('load', () => {
			PrivatePolicy.initCookieConsent();
		});
	}
}

Site.init();
