declare var gtag: any;

export default class Tracking {
    private static accepted: boolean = false;
    private static actions: (() => void)[] = [];

    public static doAction(action: () => void): void {
        if (this.accepted) {
            action();
        } else {
            this.actions.push(action);
        }
    }

    public static gtag(name1: string, name2: string, data?: any): void {
        this.doAction(() => {
            console.log('gtag', name1, name2, data);

            if ((<any>window).gtag) {
                console.log('gtagok');
                gtag(name1, name2, data);
            }
        });
    }

    public static async accept() {
        this.accepted = true;

        for (const action of this.actions) {
            await action();
        }
    }
}
